<template>
  <div id="app">

    <div>
      <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
        <b-navbar-brand><a href="https://astis.online" class="text-dark"><img src="astis.svg" alt=""></a></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="null === this.$store.state.user.id">
            <b-nav-item><router-link to="/login">Войти</router-link></b-nav-item>
            <b-nav-item><router-link to="/register">Регистрация</router-link></b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav v-if="this.$store.state.user.id">
            <b-nav-item><router-link to="/dashboard">Главная</router-link></b-nav-item>
            <b-nav-item><router-link to="/profile">Профиль</router-link></b-nav-item>
            <b-nav-item href="#" @click="onLogout()">Выйти</b-nav-item>
            <b-nav-item><router-link to="/notifications">Уведомления <span class="badge rounded-pill bg-danger text-light">{{this.$store.state.new_notify}}</span></router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <router-view style="padding-top:70px;"></router-view>
  </div>
</template>

<style>
  @import './assets/style.css';
</style>

<script>
import mixinAuth from './mixins/mixinAuth';

export default {
  name: 'App',
  mixins: [mixinAuth],
  created: function() {
    this.$store.state.token = this.$cookie.get("token");
    if(this.$store.state.token) {
      this.loadUserInfo();
    }
  },
  methods: {
    onLogout() {
      var self = this;
      self.api.get('logout')
      .then(function() {
        self.logoutUser();
        self.$router.push({'path': '/index'});
      })
      .catch(function(response) {
        alert(response);
      }); 
    }
  }
}

// TODO: при монтировании должна быть проверка на авторизацию, если авторизация есть но не выбран role_id - перенаправлять на роут register шаг 2
</script>
