<template>
    <div>
        <ChooseCategory    v-show="1 === step" v-bind:step="1" v-bind:progress="15"></ChooseCategory>
        <TechCategory      v-show="2 === step" v-bind:step="2" v-bind:progress="30" ref="category"></TechCategory>
        <Params            v-show="3 === step" v-bind:step="3" v-bind:progress="45" ref="params"></Params>
        <Location          v-show="4 === step" v-bind:step="4" v-bind:progress="60" ref="location"></Location>
        <DateTime          v-show="5 === step" v-bind:step="5" v-bind:progress="75" ref="datetime"></DateTime>
        <ToDo              v-show="6 === step" v-bind:step="6" v-bind:progress="90"></ToDo>
        <Payment           v-show="7 === step" v-bind:step="7" v-bind:progress="100"></Payment>
        <CreateOrderResult v-show="8 === step" v-bind:step="8" v-bind:progress="100"></CreateOrderResult>
    </div>
</template>

<style>
  @import '../../assets/createOrder.css';
</style>

<script>
import ChooseCategory from './Create/ChooseCategory.vue'
import TechCategory from './Create/TechCategory.vue'
import Params from './Create/Params.vue'
import Location from './Create/Location.vue'
import DateTime from './Create/DateTime.vue'
import ToDo from './Create/ToDo.vue'
import Payment from './Create/Payment.vue'
import CreateOrderResult from './Create/CreateOrderResult.vue'


export default {
    name: 'CreateOrder',
    components: {
      ChooseCategory,
      TechCategory,
      Params,
      Location,
      DateTime,
      ToDo,
      Payment,
      CreateOrderResult
    },
    data() {
      return {
        step: 1,
        formData: {},
        offerCount: 0,
      }
    },
    methods: {
      changeStep(step) {
         if(1 === this.step && 2 === step){
          this.$refs.category.updateElements();
        }
         if(2 === this.step && 3 === step){
          this.$refs.params.updateElements();
        }
        if(3 === this.step && 4 === step){
          this.$refs.location.updateElements();
        }
        if(4 === this.step && 5 === step){
          this.$refs.datetime.updateElements();
        }
        this.step = step;
        // console.log(step);
      },
      setFormData(data) {
        for (var attrname in data) { 
          this.formData[attrname] = data[attrname]; 
        }
      },
      saveOrder() {
        let self = this;
        return self.api.get('order/create', {
          'data': self.formData,
        });
      }
    }
}
</script>