<template>
    <div>
        <b-modal 
            size="sm" 
            id="modalAuthPhone" 
            hide-footer
            hide-header
        >
            <div class="text-center">
                <h6 class="my-5">На номер телефона {{ this.$store.state.phone }} был отправлен код.</h6>
                <h6 class="my-5">Введите код из смс</h6>
                <b-form v-if="show">
                    <b-form-group>
                        <div class="mx-3">
                            <div class="row">
                                <div class="col">
                                    <b-form-input
                                        id="num1"
                                        v-model="form.num1"
                                        type="text"
                                        required
                                        @input="input1Change"
                                        >
                                    </b-form-input>
                                </div>
                                <div class="col">
                                    <b-form-input
                                        id="num2"
                                        ref="num2"
                                        v-model="form.num2"
                                        type="text"
                                        required
                                        @input="input2Change"
                                        >
                                    </b-form-input>
                                </div>
                                <div class="col">
                                    <b-form-input
                                        id="num3"
                                        ref="num3"
                                        v-model="form.num3"
                                        type="text"
                                        required
                                        @input="input3Change"
                                        >
                                    </b-form-input>
                                </div>
                                <div class="col">
                                    <b-form-input
                                        id="num4"
                                        ref="num4"
                                        v-model="form.num4"
                                        type="text"
                                        required
                                        >
                                    </b-form-input>
                                </div>
                            </div>
                        </div>
                    </b-form-group>
                    <b-button variant="link" size="sm" block>Отправить еще раз</b-button>
                    <b-button @click="onSubmit()" variant="primary" class="my-4">Подтвердить</b-button>
                </b-form>                       
            </div>
        </b-modal>
    </div>
</template>


<script>

import mixinAuth from './../mixins/mixinAuth';

export default {
    mixins: [mixinAuth],
    data() {
      return {
        form: {
          num1: '',
          num2: '',
          num3: '',
          num4: '',
        },
        show: true
      }
    },
    methods: {
      onSubmit() {
        var self = this;
        let code = self.form.num1 + self.form.num2 + self.form.num3 + self.form.num4;
        self.api.get('register/phone/confirm', {"phone" : self.$store.state.phone, "code": code})
        .then(function(response) {
            if(response.data.success){
                self.loginUser(response.data.data.token);
                self.$bvModal.hide('modalAuthPhone');
                self.$router.push({'path': '/register/profile'});
                return;
            }
            alert(response.data.message);
        });
      },
      input1Change(){
          this.$refs.num2.focus()
      },
      input2Change(){
          this.$refs.num3.focus()
      },
      input3Change(){
          this.$refs.num4.focus()
      }
    }   
}
</script>
