<template>
  <div id="login">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <!-- <div class="text-left">Вы вошли как ???</div> -->
          <div class="text-left mt-5">
            <h2 class="py-3">Войти с паролем</h2>
            <p>Чтобы войти в аккаунт,<br>введите ваш телефон и пароль</p>
            <div class="mt-5">
              <div class="text-center">
                  <b-form v-if="show">
                      <b-form-group>
                          <b-form-input
                              id="phone"
                              class="no-border-input"
                              v-model="form.phone"
                              :state="validateState('phone')"
                              placeholder="79001002030"
                              type="text"
                              >
                          </b-form-input>
                          <FormInputValidate :vdata="$v.form.phone"></FormInputValidate>

                          <b-form-input
                              id="password"
                              class="no-border-input mt-4"
                              placeholder="Пароль"
                              v-model="form.password"
                              :state="validateState('password')"
                              type="text"
                              >
                          </b-form-input>
                          <FormInputValidate :vdata="$v.form.password"></FormInputValidate>
                      </b-form-group>
                      <b-button @click="onLogin()" class="btn btn-lg btn-secondary my-4">Войти</b-button>
                  </b-form>                       
                  
                  <!-- <div class="mt-3 text-muted">Нет аккаунта?</div> -->
                  <b-form>
                      <router-link to="/register"><b-button class="custom-register-submit my-2">Зарегистрироваться</b-button></router-link>
                      <router-link to="/reset"><b-button class="custom-register-submit my-2">Забыл пароль</b-button></router-link>
                  </b-form>                   
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import mixinAuth from './../mixins/mixinAuth';
import { validationMixin } from "vuelidate";
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import FormInputValidate from "./Modules/FormInputValidate.vue";

export default {
    name: 'Login',
    components: {
      FormInputValidate
    },
    mixins: [mixinAuth, validationMixin],
    data() {
      return {
        form: {
          phone: '',
          password: '',
        },
        show: true
      }
    },
    validations: {
      form: {
        phone: {
          required,
          numeric,
          minLength: minLength(11),
          maxLength: maxLength(11),
        },
        password: {
          required,
          minLength: minLength(5),
        }
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onLogin() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }

        var self = this;
        self.api.get('login', {"phone": self.form.phone, "password": self.form.password})
        .then(function(response) {
          if(response.data.data.login){
            // console.log(response);
            self.loginUser(response.data.data.token);
            self.$router.push({'path': '/dashboard'});
          } else {
            alert('Логин или пароль не верен');
          }
        });
      }
    }   
}
</script>

<style>
#auth {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
