<template>
  <div id="index">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <h2>Мой профиль</h2>
          <hr>
          <div class="row mb-4">
            <div class="col-4">
              <img v-bind:src="this.$store.state.user.avatar" class="img-fluid rounded-circle">
            </div>
            <div class="col-6">
              <h4>{{ this.$store.state.user.firstname }} {{ this.$store.state.user.lastname }}</h4>
              <!-- <div class="rating">
                ***** <span>4.9</span>
              </div> -->
              <span class="text-muted"><small>{{ this.$store.state.user.created_at }}</small></span>
            </div>
          </div>
          <router-link to="/profile/edit" class="btn btn-link">Редактировать профиль</router-link>
          <router-link to="/notifications" class="btn btn-link">Уведомления <span class="badge rounded-pill bg-danger text-light">{{this.$store.state.new_notify}}</span></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'MyProfile',
}
</script>