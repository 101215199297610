<template>
        <b-form-invalid-feedback>
            <div v-if="false === vdata.required">Поле обязательно к заполнению</div>
            <div v-if="false === vdata.numeric">Поле должно состоять только из цифр</div>
            <div v-if="false === vdata.integer">Допустимо только целое число</div>
            <div v-if="false === vdata.between && undefined !== vdata.$params.between">Значение от {{vdata.$params.between.min}} до {{vdata.$params.between.max}}</div>
            <div v-if="false === vdata.minLength && undefined !== vdata.$params.minLength">Длинна строки не менее {{vdata.$params.minLength.min}} символов</div>
            <div v-if="false === vdata.maxLength && undefined !== vdata.$params.maxLength">Длинна значения не больше {{vdata.$params.maxLength.max}} символов</div>
            <div v-if="false === vdata.decimal">Поле должно состоять только из целого или десятичного значения</div>
            <div v-if="false === vdata.decimal2">После разделителя только 1 или 2 цифры, например 500.5 или 500.55</div>
            <div v-if="false === vdata.email">В этом поле может быть указан только email</div>
        </b-form-invalid-feedback>
</template>
<script>
export default {
    name: 'FormInputValidate',
    props: ['vdata'],
}
</script>