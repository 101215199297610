<template>
    <div id="index">
    </div>
</template>

<script>
export default {
    name: "Refs",
    props: ['referral_code', 'order_id', 'worker', 'client'],
    created: function() {
        console.log(this.$route.query.code)
        this.$cookie.set("referral_code", this.$route.query.code);
        this.$router.push({'path': '/register'});
    },
};
</script>