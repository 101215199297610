<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Создание заказа</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Укажите меcто<br>проведения работ</h5>
        <hr>
      </div> 
      <b-form class="mb-5">
        <b-form-group id="input-group-3" label="Регион:" label-for="select-1">
            <b-form-select class="no-border-input" @change="onRegionSelected($event)" :state="validateState('region_id')" v-model="form.region_id" :options="options.regions"></b-form-select>
            <FormInputValidate :vdata="$v.form.region_id"></FormInputValidate>
        </b-form-group>
        <b-form-group id="input-group-3" label="Город:" label-for="select-2" v-if="options.cities.length">
            <b-form-select class="no-border-input" v-model="form.city_id" :state="validateState('city_id')" placeholder="Выберите город" :options="options.cities"></b-form-select>
            <FormInputValidate :vdata="$v.form.city_id"></FormInputValidate>
        </b-form-group>
        <b-form-group :id="'input-group'" label="Адрес:" :label-for="'textarea'" v-if="options.cities.length">
            <b-form-textarea
                :id="'textarea'"
                :state="validateState('address')"
                class="no-border-input"
                v-model="form.address"
            ></b-form-textarea>
            <FormInputValidate :vdata="$v.form.address"></FormInputValidate>
        </b-form-group>
      </b-form>
      
      <div class="clearfix">
            <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
            <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
        </div>  

        <!-- <div class="text-center text-yellow font-italic mt-5">
            {{offerCount}} подходящих предложений
        </div> -->
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
import FormInputValidate from "../../Modules/FormInputValidate.vue";

export default {
    name: 'Location',
    components: {
      FormInputValidate
    },
    mixins: [validationMixin],
    props: ['step', 'progress'],
    data() {
        return {
            if_property: false,
            options: {
                regions: [],
                cities: []
            },
            form: {},
            offerCount: 0,
        }
    },
    validations: {
        form: {
            region_id: {
                required,
            },
            city_id: {
                required,
            },
            address: {
                required,
            },
        }
    },
    created: function () {
        var self = this;
        self.api.get('reference-book/regions')
        .then(function(response) {
            self.options.regions = response.data;
        }); 
    },
    methods: {
        updateElements() {
            this.getOffer();
        },
        getOffer(){
            this.api.get("tech/offers", {"tech_category_id" : this.$parent.formData.category_id}).then(
                function (response) {
                    this.offerCount = response.data;
                }.bind(this)
            );
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        onRegionSelected() {
            var self = this;
            self.api.get('reference-book/cities', {"region_id": this.form.region_id})
            .then(function(response) {
                self.options.cities = response.data;
            }); 
        },
        prevStep() {
            this.$parent.changeStep(this.step - 1);
        },
        nextStep() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$parent.setFormData(this.form);
            this.$parent.changeStep(this.step + 1);
        }
    }
}
</script>