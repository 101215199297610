<template>
    <div class="link_back">
        <div @click="actionBack">Назад</div>
    </div>
</template>
<script>
export default {
    name: 'LinkBack',
    props: [],
    methods: {
        actionBack: function(){
            this.$router.back();
        }
    }
}
</script>