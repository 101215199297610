<template>
    <div>
        <div class="order-detail" v-for="element in elements" :key="element.id">
            <strong>{{element.label}}:</strong> {{element.text}}
        </div>
    </div>
</template>
<script>
export default {
    name: 'ValueParams',
    props: ['elements'],
}
</script>