<template>
    <div id="index">
        <div class="container">
            <div class="text-center mt-5">
                <h2 class="mb-4">Заказ создан</h2>
                <p>Спасибо. Ваш заказ<br>размещен в системе -<br>ждите первых откликов</p>
            </div>  

            
            <div class="text-center mt-5">
                <router-link :to="{ 'name': 'myOrdersClient'}" class="btn btn-secondary btn-lg">Мои заказы</router-link>
            </div>     
            

        </div>
    </div>
</template>

<script>

export default {
    name: 'CreateOrderResult',
    props: ['step', 'progress'],
    data() {
        return {
            form: {},
        }
    },
    created: function () {
      
    },
    methods: {

    }
}
</script>