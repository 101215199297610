<template>
    <div>
        <div v-if="1 === order.status">
            <span class="order-status">
                <strong>Активный</strong>
            </span>
        </div>
        <div v-if="2 === order.status">
            <span class="order-status">
                <strong>В работе</strong>
            </span>
        </div>
        <div v-if="3 === order.status">
            <span class="order-status">
                <strong>Завершен</strong>
            </span>
        </div>
        <div v-if="4 === order.status">
            <span class="order-status">
                <strong>В архиве</strong>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderStatusLabel",
    props: ["order"]
};
</script>
