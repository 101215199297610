<template>
    <div id="index">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="mb-4">Создание заказа</h2>
                <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
                <h5>Выберите удобный<br>способ оплаты</h5>
                <hr>
            </div> 
            <div class="row">
                <div class="col-10 offset-2">
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox1'"
                            :name="'checkbox-name1'"
                            :value="1"
                            v-model="form.pay_method_1"
                        >Наличные</b-form-checkbox>
                    </b-form-group>    
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox2'"
                            :name="'checkbox-name2'"
                            :value="1"
                            v-model="form.pay_method_2"
                        >Перевод на карту</b-form-checkbox>
                    </b-form-group>    
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox3'"
                            :name="'checkbox-name3'"
                            :value="1"
                            v-model="form.pay_method_3"
                        >Оплата по счету (с НДС)</b-form-checkbox>
                    </b-form-group>    
                    <b-form-group>
                        <b-form-checkbox
                            :id="'checkbox4'"
                            :name="'checkbox-name4'"
                            :value="1"
                            v-model="form.pay_method_4"
                        >Оплата по счету (без НДС)</b-form-checkbox>
                    </b-form-group>
                </div>
            </div> 
            

            <div class="clearfix">
                <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
                <a @click="nextStep" class="btn btn-secondary btn-lg float-right">Создать заказ</a>
            </div>  

            <!-- <div class="text-center text-yellow font-italic mt-5">
                {{this.$parent.offerCount}} подходящих предложений
            </div> -->
        </div>
    </div>
</template>

<script>

export default {
    name: 'Payment',
    props: ['step', 'progress'],
    data() {
        return {
            form: {},
        }
    },
    created: function () {
      
    },
    methods: {
        prevStep() {
            this.$parent.changeStep(this.step - 1);
        },
        nextStep() {
            var self = this;
            self.$parent.setFormData(self.form);
            self.$parent.saveOrder().then(function(){
                self.$parent.changeStep(self.step + 1);
            });
        }
    }
}
</script>