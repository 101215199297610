<template>
    <div id="index">
        <div class="container">
            <template>
                <div class="link_back">
                    <router-link :to="{ 'name': 'orderClientShow', 'params': { order_id: order_id } }" v-if="client">Назад в заказ</router-link>
                    <router-link :to="{ 'name': 'orderWorkerShow', 'params': { order_id: order_id } }" v-if="worker">Назад в заказ</router-link>     
                </div>
            </template>
            <div class="text-center">
                <h2 class="mb-4">Заказ № {{order_id}}</h2>
                <hr>
            </div>
            <div class="messages" v-html="outputValueOnEnter"></div>
            
            <div style="margin-top:20px;">
                <b-input-group class="mb-3">
                    <b-form-textarea v-model="newMsg" placeholder="Сообщение" v-on:keyup.ctrl.enter="submit"></b-form-textarea>
                    <b-input-group-append>
                        <b-button text="Button" variant="success" v-on:click="submit">Отправить</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Chat",
    props: ['target_id', 'order_id', 'worker', 'client'],
    components: {},
    data() {
        return {
          outputValueOnEnter: '',
          newMsg: null,
          timerId: null
        };
    },
    mounted: function() {
        if (!this.order_id || !this.target_id) {
            this.$router.push({ path: "/orders/client" });
        }
        this.getData(); 
        this.timerId = setInterval(this.getData, 3000);
    },
    beforeDestroy: function() {
        clearInterval(this.timerId);
    },
    methods: {
        formatMsg: function(data) {
            return '<div class="msg ' + (1 === data.is_my ? 'is_my text-right' : '') + '">'
            +data.msg
            +' <div class="chat_date"> '+data.date + '</div>'
            +'</div>';
        },
        getData: function () {
            let html = '';
            this.api.get("chat/history", { data: {"order_id": this.order_id} }).then(
                function (response) {
                    Object.keys(response.data).map(function(index){
                        let row = response.data[index];
                        html += this.formatMsg(row);
                    }.bind(this))
                    this.outputValueOnEnter = html;
                }.bind(this)
            );
        },
        submit: function () {
            this.api.get("chat/write", { data: {"msg": this.newMsg, "target_id": this.target_id, "order_id": this.order_id} }).then(
                function (response) {
                    if(true === response.data.success) {
                        this.getData();
                        this.newMsg = null;
                    }
                }.bind(this)
            );
        },
    }
};
</script>