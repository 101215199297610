<template>
    <div>
        <div class="text-center mt-5">
            <div v-if="2 == tech.status">Техника на заказе</div>
            <a v-if="1 == tech.status"
                href="javascript:"
                class="btn btn-lg btn-outline-secondary"
                @click="actionToArchive"
                >В архив
            </a>&nbsp;
            <a v-if="3 == tech.status"
                href="javascript:"
                class="btn btn-lg btn-outline-secondary"
                @click="actionFromArchive"
                >Вернуть в список
            </a>&nbsp;
            <a v-if="3 == tech.status"
                href="javascript:"
                class="btn btn-lg btn-outline-secondary"
                @click="actionDelete"
                >Удалить
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "TechControlBtn",
    props: ["tech"],
    data() {
        return {};
    },
    methods: {
        actionToArchive: function() {
            this.api.get("tech/change/status", { tech_id: this.tech.id, status: 3}).then(
                function() {
                    this.$router.push({ path: "/tech/list" });
                }.bind(this)
            );
        },
        actionFromArchive: function() {
            this.api.get("tech/change/status", { tech_id: this.tech.id, status: 1}).then(
                function() {
                    this.$router.push({ path: "/tech/list" });
                }.bind(this)
            );
        },
        actionDelete: function() {
            this.api.get("tech/delete", { tech_id: this.tech.id}).then(
                function() {
                    this.$router.push({ path: "/tech/list" });
                }.bind(this)
            );
        }
    }
};
</script>
