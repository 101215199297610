var mixinAuth = {
  methods: {
    loginUser: function(token) {
      this.$store.state.token = token;
      this.$cookie.set("token", token);
      this.loadUserInfo();
    },
    logoutUser: function() {
      this.$store.state.user = {
        avatar: null,
        balance:null,
        balanceStr: null,
        city_id: null,
        company_inn: null,
        company_name: null,
        company_type: null,
        contact_phone: null,
        created_at: null,
        deleted_at: null,
        email: null,
        email_verified_at: null,
        firstname: null,
        fullname: null,
        id: null,
        lastname: null,
        name: null,
        phone: null,
        phone_confirm_code: null,
        phone_confirmed: null,
        referal_id: null,
        referral_code: null,
        referral_link: null,
        referral_list: null,
        referrer_id: null,
        referrer_payed: null,
        region_id: null,
        role_id: null,
        updated_at: null,
      };
      this.$store.state.new_notify = 0;
      this.$store.state.token = null;
      this.$cookie.delete("token");
    },
    loadUserInfo: async function () {
      let self = this;
      console.log('load user info');
      let response = await self.api.get('auth-info');      
      if(undefined === response){
        this.logoutUser();
      }
      self.$store.state.user = response.data;
      if(null === response.data.role_id) {
        self.$router.push({'path': '/register/profile'});
      }
      this.checkNotify();
    },

    checkNotify: async function () {
      let self = this;   
      let init = function(self) {
        self.api.get("notifications/count", { data: { user_type_id: self.$store.state.user.role_id, readed: 0 } }).then(
          function(response) {
            self.$store.state.new_notify = response.data;
            console.log(self.$store.state.new_notify);
          }
        );
      }

      init(self);
      setInterval(() => init(self), 3000);
    }
  }
}

export default mixinAuth;