<template>
    <div>
        <div class="text-center mt-5" v-if="2 === order.status">
            <a
                href="javascript:"
                class="btn btn-lg btn-outline-secondary"
                @click="openModal"
                >Завершить заказ
            </a>
        </div>

        <b-modal
            id="orderCompleteModal"
            title="Завершение заказа"
            hide-footer
            hide-header
        >
            <div class="text-center">
                <h4 class="mb-5 mt-5">
                    Вы действительно хотите завершить заказ?
                </h4>
                <div class="mt-5">
                    <router-link
                        :to="{
                            name: 'orderComplete',
                            params: {
                                order_id: order.id,
                                client: client,
                                worker: worker
                            }
                        }"
                        class="btn btn-secondary btn-lg btn-block mt-3"
                        >Завершить</router-link
                    >
                </div>
                <div class="mt-1">
                    <a
                        @click="closeModal"
                        class="btn btn-outline-secondary btn-lg btn-block mt-3"
                        >Отмена
                    </a>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "orderCompleteModal",
    props: ["order", "client", "worker"],
    data() {
        return {};
    },
    created: function() {
        console.log(this.order);
    },
    methods: {
        openModal: function() {
            this.$bvModal.show("orderCompleteModal");
        },
        closeModal: function() {
            this.$bvModal.hide("orderCompleteModal");
        }
    }
};
</script>
