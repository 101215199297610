<template>
    <b-form class="mb-5">
        <div v-for="element in elements" :key="element.id">
            <template v-if="1 === element.input_type">
                <b-form-group :id="'input-group-' + element.id" :label="element.label" :label-for="'input-' + element.id">
                    <b-form-input
                        :id="'input-' + element.id"
                        v-model="form[element.id]"
                        :state="validateState(element.id)"
                    ></b-form-input>
                    <FormInputValidate :vdata="$v.form[element.id]"></FormInputValidate>
                </b-form-group>
            </template>
            <template v-if="2 === element.input_type">
                <b-form-group :id="'input-group-' + element.id" :label="element.label" :label-for="'textarea-' + element.id">
                    <b-form-textarea
                        :id="'textarea-' + element.id"
                        v-model="form[element.id]"
                        :state="validateState(element.id)"
                    ></b-form-textarea>
                    <FormInputValidate :vdata="$v.form[element.id]"></FormInputValidate>
                </b-form-group>
            </template>
            <template v-if="3 === element.input_type">
                <b-form-group :id="'input-group-' + element.id" :label="element.label" :label-for="'select-' + element.id">
                    <b-form-select
                        :id="'select-' + element.id"
                        :options="element.options"
                        v-model="form[element.id]"
                        :state="validateState(element.id)"
                    ></b-form-select>
                    <FormInputValidate :vdata="$v.form[element.id]"></FormInputValidate>
                </b-form-group>
            </template>
            <template v-if="4 === element.input_type">
                <b-form-group :id="'input-group-' + element.id" :label="element.label" :label-for="'radio-' + element.id">
                    <b-form-radio v-for="option in element.options" 
                    v-model="form[element.id]"
                    :key="'radio-' + option.id" 
                    :name="'radio-name-' + element.id" 
                    :value="option.value"
                    :state="validateState(element.id)">{{option.text}}</b-form-radio>
                    <FormInputValidate :vdata="$v.form[element.id]"></FormInputValidate>
                </b-form-group>
            </template>
            <template v-if="5 === element.input_type">
                <b-form-checkbox
                    :id="'checkbox' + element.id"
                    :name="'checkbox-name-' + element.id"
                    :value="1"
                    v-model="form[element.id]"
                >{{element.label}}</b-form-checkbox>
            </template>
            <template v-if="6 === element.input_type">
                <div class="row mb-2 pt-2">
                    <div class="col-7">
                        {{element.label}}
                    </div>
                    <div class="col-5">         
                        <b-form-group>
                            <b-form-row>
                                <b-col>
                                    <b-form-input
                                    type="number"
                                    :id="'range-group-start-' + element.id"
                                    placeholder="От"
                                    v-model="element.value_start"
                                    required
                                    ></b-form-input>
                                </b-col>
                                <b-col>
                                    <b-form-input
                                    type="number"
                                    :id="'range-group-end-' + element.id"
                                    placeholder="До"
                                    v-model="element.value_end"
                                    required
                                    ></b-form-input>
                                </b-col>
                            </b-form-row>
                        </b-form-group>
                    </div>
                </div>
            </template>
        </div>    
    </b-form>
</template>
<script>

import { validationMixin } from "vuelidate";
import { required, maxLength } from 'vuelidate/lib/validators'
import FormInputValidate from "./FormInputValidate.vue";

export default {
    name: 'FormParams',
    components: {
      FormInputValidate
    },
    mixins: [validationMixin],
    props: [ 'elements' ],
    data() {
        return {
            form: {}
        }
    },
    validations() {
        let validate = {form: {}};
        Object.keys(this.elements).map(function(objectKey) {
            if(1 === this.elements[objectKey].input_type) {
                validate.form[this.elements[objectKey].id] = {
                    maxLength: maxLength(15)
                };
                if(this.elements[objectKey].requied){
                    validate.form[this.elements[objectKey].id].required = required;
                }
            }else if(2 === this.elements[objectKey].input_type) {
                validate.form[this.elements[objectKey].id] = {
                    maxLength: maxLength(30)
                };
                if(this.elements[objectKey].requied){
                    validate.form[this.elements[objectKey].id].required = required;
                }
            }else if(3 === this.elements[objectKey].input_type) {
                validate.form[this.elements[objectKey].id] = {};
                if(this.elements[objectKey].requied){
                    validate.form[this.elements[objectKey].id].required = required;
                }
            }else if(4 === this.elements[objectKey].input_type) {
                validate.form[this.elements[objectKey].id] = {};
                if(this.elements[objectKey].requied){
                    validate.form[this.elements[objectKey].id].required = required;
                }
            }
        }.bind(this));        
        return validate;
    },
    methods: {
      validateState(param_id) {
        // if(undefined === this.$v.form[param_id]){
        //     return null;
        // }
        // console.log('runState'+param_id);
        const { $dirty, $error } = this.$v.form[param_id];
        let check = $dirty ? !$error : null;
        // if(check) {
            Object.keys(this.elements).map(function(objectKey) {
                if(param_id === this.elements[objectKey].id) {
                    this.elements[objectKey].value = this.form[param_id];
                }
            }.bind(this));
        // }
        return check;
      },
      check() {
        this.$v.$touch();
        let check = false === this.$v.form.$anyError ? true : false;
        console.log(check);
        return check;
      }
    },
    
}
</script>