import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
import './plugins/bootstrap-vue'
import apiAstis from './class/apiAstis'
import helpers from './class/helpers'
import axios from 'axios'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import routes from './routes'
import config from './config'

Vue.prototype.axios = axios
Vue.prototype.config = config
Vue.prototype.helpers = new helpers()

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueCookie)
Vue.use(Vuelidate)

const store = new Vuex.Store({
  state: {
    phone: null,
    name: 'Гость',
    user: {
      avatar: null,
      balance:null,
      balanceStr: null,
      city_id: null,
      company_inn: null,
      company_name: null,
      company_type: null,
      contact_phone: null,
      created_at: null,
      deleted_at: null,
      email: null,
      email_verified_at: null,
      firstname: null,
      fullname: null,
      id: null,
      lastname: null,
      name: null,
      phone: null,
      phone_confirm_code: null,
      phone_confirmed: null,
      referal_id: null,
      referral_code: null,
      referral_link: null,
      referral_list: null,
      referrer_id: null,
      referrer_payed: null,
      region_id: null,
      role_id: null,
      updated_at: null,
    },
    token: null,
    new_notify: 0,
  }
})

const router = new VueRouter({
  routes
})

Vue.prototype.api = new apiAstis(Vue.prototype, store);

new Vue({
  render: h => h(App),
  store: store,
  router
}).$mount('#app')
