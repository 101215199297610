<template>
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2" v-if="1 === step">
          <h3>Пользовательское соглашение</h3>
          <h4>1. Общие положения</h4>
        <p>
        Настоящее Пользовательское соглашение регулирует отношения между ООО &laquo;КИБЕ-ТРЕЙД&raquo; (далее&nbsp;&mdash; &laquo;Общество&raquo; и&nbsp;пользователем сети Интернет (далее&nbsp;&mdash; &laquo;Пользователь&raquo;), возникающие при использовании Интернет-ресурса astis.online (далее&nbsp;&mdash; &laquo;Сайт&raquo;). В&nbsp;соответствии со&nbsp;ст.&nbsp;435&nbsp;ГК РФ&nbsp;настоящее Пользовательское соглашение, (далее&nbsp;&mdash; &laquo;Соглашение&raquo;), является офертой, адресованной неограниченному кругу лиц. В&nbsp;соответствии со&nbsp;ст.&nbsp;428 и&nbsp;ч.5 ст.1286&nbsp;ГК РФ&nbsp;Соглашение является договором присоединения и&nbsp;заключается в&nbsp;упрощенном порядке путем присоединения к&nbsp;условиям настоящего соглашения.
        </p>
        <p>
        Целью Сайта является предоставление Пользователям, услуг, в&nbsp;качестве площадки для нахождения друг друга. Пользователи самостоятельно решают с&nbsp;кем и&nbsp;на&nbsp;каких условиях работать, и&nbsp;сами несут ответственность за&nbsp;действия, совершенные ими в&nbsp;дальнейшем.
        </p>
        <p>
        Правом пользования данными услугой обладают юридические и&nbsp;физические лица, прошедшие процедуру регистрации на&nbsp;Сайте. Регистрация осуществляется путем заполнения регистрационной формы и&nbsp;нажатия кнопки &laquo;зарегистрироваться&raquo;. Регистрация является акцептом настоящей оферты в&nbsp;соответствии со&nbsp;ст.&nbsp;438&nbsp;ГК РФ, т.е. безусловным принятием условий настоящего Соглашения и&nbsp;выражения согласия со&nbsp;всеми его условиями. 
        </p>
        <p>
        Деятельность Общества по&nbsp;сбору, хранению, систематизации информации, размещенной на&nbsp;Сайте, а&nbsp;также право доступа к&nbsp;такой информации осуществляются в&nbsp;соответствии с&nbsp;положениями ФЗ &#8470;&nbsp;149 &laquo;Об&nbsp;информации, информационных технологиях и&nbsp;защите информации&raquo;
        </p>
        <p>Регистрируясь на&nbsp;сайте, пользователь выражает свое согласие на&nbsp;обработку его персональных данных в&nbsp;случаях, когда получение такого согласия требуется в&nbsp;соответствии с&nbsp;ФЗ &#8470;&nbsp;152 &laquo;О&nbsp;защите персональных данных&raquo;. Этим он&nbsp;также выражает согласие на&nbsp;получение информационных сообщений от&nbsp;Общества по&nbsp;каналам телефонной связи и&nbsp;посредством интернет-мессенджеров.</p>
        <p>Общество в&nbsp;соответствии с&nbsp;п.1&nbsp;ст.&nbsp;22&nbsp;ФЗ &#8470;&nbsp;152&nbsp;в свою очередь обязуется уведомить уполномоченный орган по&nbsp;защите прав субъектов персональных данных о&nbsp;своем намерении осуществлять обработку персональных данных. </p>
        <h4>2. Термины и&nbsp;определения, используемые в&nbsp;Соглашении</h4>
        <p>2.1 Сайт&nbsp;&mdash; результат интеллектуальной деятельности, представляющий собой составное произведение, расположенное в&nbsp;информационно-телекоммуникационной сети под определенным сетевым адресом и&nbsp;включающее в&nbsp;себя программы для ЭВМ, обеспечивающие его функционирование, графическое решение (дизайн), контент (текстовую информацию), размещенный на&nbsp;нем, а&nbsp;также иные результаты интеллектуальной деятельности, в&nbsp;частности фотографические изображения, видеозаписи и&nbsp;др. Под Сайтом в&nbsp;тексте настоящего Соглашения понимается Сайт, расположенный в&nbsp;сети Интернет под доменным именем</p>
        <p>2.2 Услуга&nbsp;&mdash; любой товар, продукт, услуга или иное предложение, которое пользователь сможет разместить в&nbsp;своем Профиле.</p>
        <p>2.3 Сервисы&nbsp;&mdash; функциональные возможности, службы, услуги, инструменты, доступные для Пользователей на&nbsp;Сайте</p>
        <p>2.4 Посетитель Сайта&nbsp;&mdash; любое лицо, получившее доступ к&nbsp;сайту и&nbsp;его открытым данным путем сети интернет</p>
        <p>2.5 Пользователь&nbsp;&mdash; любое юридическое и&nbsp;физическое лицо, прошедшее регистрацию на&nbsp;сайте и&nbsp;имеющее свой личный кабинет на&nbsp;сайте. Пользователю сайта доступен его полный функционал и&nbsp;право размещения и&nbsp;принятия заявок, в&nbsp;порядке, указанном ниже в&nbsp;Соглашении. При регистрации на&nbsp;сайте Пользователь создает индивидуальный аккаунт&nbsp;&mdash; совокупность всей имеющейся информации о&nbsp;Пользователе, зарегистрированном на&nbsp;Сайте: уникальное имя (логин и&nbsp;пароль для входа на&nbsp;Сайт, указываемые при регистрации на&nbsp;Сайте), а&nbsp;также любая иная информация о&nbsp;нем, размещаемая на&nbsp;Сайте. </p>
        <p>2.6 Регистрация&nbsp;&mdash; совокупность действий Пользователя в&nbsp;соответствии с&nbsp;указанными на&nbsp;Сайте инструкциями, включая предоставление Учетных данных и&nbsp;иной информации, совершаемых Пользователем с&nbsp;использованием специальной формы пользовательского интерфейса Сайта в&nbsp;целях формирования Личного кабинета и&nbsp;получения доступа к&nbsp;отдельным Сервисам Сайта.</p>
        <p>2.7 Аккаунт является неотъемлемой частью Сайта. Собственником Аккаунта является Общество. Все права по&nbsp;удалению Аккаунта принадлежат Обществу как собственнику. После регистрации на&nbsp;Сайте Пользователь получает Аккаунт во&nbsp;временное владение и&nbsp;пользование. Если Пользователь отказался от&nbsp;дальнейшего использования Аккаунта (уведомил Общество или совершил иные действия, явно свидетельствующие об&nbsp;отказе от&nbsp;дальнейшего использования Аккаунта), то&nbsp;он&nbsp;утрачивает права владения и&nbsp;пользования Аккаунтом. При этом все права по&nbsp;изменению информации в&nbsp;таком Аккаунте, удалению информации из&nbsp;Аккаунта, а&nbsp;также любые иные действия, связанные с&nbsp;Аккаунтом, переходят в&nbsp;полном объеме к&nbsp;Обществу. Информация, размещенная в&nbsp;Аккаунте, может частично (или полностью) удаляться по&nbsp;решению Общества в&nbsp;случаях, если сохранение такой информации явно противоречит или нарушает законодательство РФ. Лицо, создавшее Аккаунт обязуется не&nbsp;продавать, не&nbsp;передавать Аккаунт и&nbsp;не&nbsp;отчуждать его иным образом. </p>
        <p>2.8 Личный кабинет Пользователя/ Профиль&nbsp;&mdash; защищенная страница на&nbsp;сайте, сформированная при создании Аккаунта, обладающая собственной структурой и&nbsp;определенными функциями. Доступ к&nbsp;Личному кабинету осуществляется путем ввода логина и&nbsp;пароля. </p>
        <p>2.9 Исполнитель&nbsp;&mdash; Пользователь, прошедший процедуру регистрации на&nbsp;Сайте, имеющий свой личный (Профиль/аккаунт) и&nbsp;информацию, размещенную на&nbsp;Сайте в&nbsp;конкретном случае, с&nbsp;целью поиска и&nbsp;привлечения клиентов/Заказчиков Услуг.</p>
        <p>2.10 Заказчик&nbsp;&mdash; Пользователь, прошедший процедуру регистрации на&nbsp;Сайте, имеющий свой личный (Профиль/аккаунт) и&nbsp;информацию, размещенную на&nbsp;Сайте в&nbsp;конкретном случае, с&nbsp;целью поиска и&nbsp;привлечения Исполнителей Услуг.</p>
        <p>2.11 Астис (балл)&nbsp;&mdash; номинальная единица, используемая для оплаты услуг Общества при принятии заказов Пользователями&nbsp;&mdash; Исполнителями услуг. Один Астис эквивалентен 1&nbsp;рублю. Пользователи оплачивают покупку Астисов у&nbsp;Общества и&nbsp;используют их&nbsp;в&nbsp;дальнейшем для акцептирования заявки Заказчика или иных Сервисов Сайта. Общество вправе начислять Астисы активным Пользователям сайта, а&nbsp;также при проведении различных рекламных акций. Порядок и&nbsp;условия регулируются Обществом самостоятельно, о&nbsp;чем на&nbsp;Сайте публикуются соответствующие уведомления. </p>
        <h4>3. Предмет соглашения</h4>
        <p>3.1 Сайт предоставляется Заказчикам и&nbsp;Исполнителям услуг, в&nbsp;качестве площадки для нахождения друг друга. Заказчики и&nbsp;Исполнители услуг, самостоятельно решают с&nbsp;кем и&nbsp;на&nbsp;каких условиях работать, и&nbsp;сами несут ответственность за&nbsp;действия, совершенные ими в&nbsp;дальнейшем.</p>
        <p>3.2 Предметом настоящего Соглашения является предоставление Обществом площадки, позволяющей Пользователям самостоятельно на&nbsp;свой страх и&nbsp;риск размещать и&nbsp;принимать заявки на&nbsp;услуги. Заказчик услуги под свою ответственность принимает предложения, размещенные на&nbsp;Сайте, и&nbsp;далее заключает соответствующее соглашение с&nbsp;Исполнителем. Общество не&nbsp;является стороной данного договора на&nbsp;оказание услуг. Его организатором, посредником, агентом или представителем какого-либо Пользователя, а&nbsp;также иным заинтересованным лицом в&nbsp;отношении предполагаемого/заключенного соглашения между Заказчиками и&nbsp;Исполнителями услуг. Объявления на&nbsp;Сайте и&nbsp;последующие соглашения по&nbsp;предоставлению услуг заключаются и&nbsp;исполняются без прямого или косвенного участия Общества. </p>
        <p>3.3 Общество оставляет за&nbsp;собой право самостоятельно изменять условия настоящего Соглашения без согласования с&nbsp;его Пользователями. Пользователь Сайта обязуется самостоятельно осуществлять контроль за&nbsp;изменениями в&nbsp;Пользовательском соглашении. При несоблюдении данной обязанности, ответственность, а&nbsp;также все негативные последствия возлагаются исключительно на&nbsp;самого Пользователя. </p>
        <p>3.4&nbsp;В случае несогласия с&nbsp;новой редакцией Пользовательского соглашения, Пользователь Сайта должен прекратить пользоваться Сайтом, а&nbsp;также уведомить Администрацию об&nbsp;отказе от&nbsp;пользования Сайтом. Если Пользователь Сайта не&nbsp;уведомил Общество о&nbsp;своем несогласии, считается, что он&nbsp;согласился с&nbsp;новой редакцией Пользовательского соглашения.</p>
        <p>3.5 Принимая условия данного Соглашения, Пользователь Сайта дает свое согласие на&nbsp;получение от&nbsp;Общества информационных, рекламных и&nbsp;иных видов рассылок посредством SMS и&nbsp;звонков на&nbsp;указанный в&nbsp;Профиле номер телефона Пользователя, а&nbsp;также на&nbsp;адрес его электронной почты мессенджеров и&nbsp;обязуется не&nbsp;предъявлять каких-либо претензий и&nbsp;требований, связанных с&nbsp;осуществлением таких рассылок.</p>
        <h4>4. Права и&nbsp;обязанности сторон</h4>
        <p>4.1 Права и&nbsp;обязанности Общества:</p>
        <p>4.1.1. Общество обязуется:</p>
        <p>4.1.1.1. Предоставить Пользователю право пользования Сайтом;</p>
        <p>4.1.1.2. Предоставить Пользователю возможность пользоваться платными Сервисами, имеющимся на&nbsp;Сайте, на&nbsp;условиях, установленных в&nbsp;настоящем Соглашении, в&nbsp;соответствии с&nbsp;видом такого сервиса;</p>
        <p>4.1.1.3. Не&nbsp;разглашать данные Пользователя, указанные им&nbsp;при регистрации (логин и&nbsp;пароль). Общество не&nbsp;несет ответственности за&nbsp;их&nbsp;получение третьими лицами не&nbsp;по&nbsp;его вине.</p>
        <p>4.1.1.4. При обработке персональных данных принимать необходимые правовые, организационные и&nbsp;технические меры для защиты персональных данных от&nbsp;неправомерного или случайного доступа к&nbsp;ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а&nbsp;также от&nbsp;иных неправомерных действий в&nbsp;отношении персональных данных. Указанные меры применяются в&nbsp;соответствии с&nbsp;ФЗ &#8470;&nbsp;152-ФЗ. В&nbsp;целях координации действий по&nbsp;обеспечению безопасности персональных данных Общество назначает лиц, ответственных за&nbsp;обеспечение безопасности персональных данных.</p>
        <p>4.1.1.5. Общество не&nbsp;проводит специальных проверок сведений, которые Исполнители услуг указывают о&nbsp;себе при регистрации, а&nbsp;также сведений, размещаемых ими в&nbsp;своем профиле. Общество не&nbsp;гарантирует качество, безопасность и&nbsp;законность услуг, достоверность данных, размещенных в&nbsp;Профиле Пользователя, уровень его квалификации для выполнения каких-либо Услуг.</p>
        <p>4.1.2. Общество имеет право:</p>
        <p>4.1.2.1. По&nbsp;своему усмотрению в&nbsp;одностороннем порядке изменять стоимость платных Сервисов с&nbsp;обязательным уведомлением об&nbsp;этом посредством размещения соответствующей информации на&nbsp;Сайте.</p>
        <p>4.1.2.2. Общество вправе передавать данные Пользователей их&nbsp;контрагентам, которые Пользователи указали при регистрации на&nbsp;Сайте, в&nbsp;целях заключения соглашений для реализации целей и&nbsp;предмета настоящего Пользовательского Соглашения.</p>
        <p>4.1.2.3. В&nbsp;случае нарушения Пользователем условий Соглашения, а&nbsp;также предоставления им&nbsp;неверных сведений при регистрации</p>
        <p>а) отказаться от&nbsp;исполнения настоящего Соглашения;</p>
        <p>б) заблокировать доступ Пользователя к&nbsp;Аккаунту;</p>
        <p>в) аннулировать Личный Кабинет Пользователя;</p>
        <p>г) приостановить доступ к&nbsp;сервисам Пользователя на&nbsp;определенный срок;</p>
        <p>д) ограничить Пользователю доступ к&nbsp;сервисам на&nbsp;определенный срок или без определения срока;</p>
        <p>4.1.2.4. Осуществлять Модерацию Сайта.</p>
        <p>4.1.2.5. Вносить изменения в&nbsp;техническое оснащение и&nbsp;компьютерный код Сайта, а&nbsp;именно устанавливать дополнительные приложения, инструменты и&nbsp;т.д.</p>
        <p>4.1.2.6. В&nbsp;любой момент потребовать от&nbsp;Пользователя подтверждения данных, указанных при регистрации, и&nbsp;запросить, в&nbsp;связи с&nbsp;этим, подтверждающие документы. </p>
        <p>4.1.2.7. Производить сбор, хранение, систематизацию и&nbsp;использование информации о&nbsp;деятельности Пользователя на&nbsp;Сайте, в&nbsp;частности, статистику посещений Сайта, используемые сервисы и&nbsp;т.д.;</p>
        <p>4.1.2.8. В&nbsp;соответствии с&nbsp;условиями Соглашения осуществлять свои права в&nbsp;отношении Аккаунта, в&nbsp;том числе принимать решение об&nbsp;удалении/не удалении с&nbsp;Сайта Аккаунта/информации, содержащейся в&nbsp;Аккаунте.</p>
        <p>4.1.2.9. Общество не&nbsp;несет ответственности за&nbsp;выполнение условий принятых заявок на&nbsp;сайте. Оно не&nbsp;является стороной соглашений между Пользователями Общества </p>
        <p>4.2. Права и&nbsp;обязанности Пользователя Сайта:</p>
        <p>4.2.1. Пользователь обязуется:</p>
        <p>4.2.1.1. Соблюдать условия настоящего Соглашения.</p>
        <p>4.2.1.2. Использовать персональные данные, размещенные на&nbsp;Сайте в&nbsp;соответствии с&nbsp;Федеральным законом &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; и&nbsp;всеми вносимыми в&nbsp;него изменениями в&nbsp;течение срока действия Соглашения, а&nbsp;именно:</p>
        <ul>
        <li>использовать персональные данные из&nbsp;Базы Данных только с&nbsp;целью обеспечения функционирования Сайта;</li>
        <li>не&nbsp;передавать сведения о&nbsp;других Пользователях, полученные на&nbsp;Сайте, третьим лицам;</li>
        <li>если Пользователь сохраняет копии данных Аккаунта Пользователей из&nbsp;Базы Данных на&nbsp;бумажных или электронных носителях, то&nbsp;он&nbsp;обязан делать это в&nbsp;соответствии с&nbsp;Федеральным законом &#8470;&nbsp;152-ФЗ от&nbsp;27&nbsp;июля 2006&nbsp;г.&nbsp;и&nbsp;всеми вносимыми в&nbsp;него изменениями в&nbsp;течение срока действия Соглашения, принимая на&nbsp;себя все обязательства оператора в&nbsp;терминах данного закона;</li>
        <li>в&nbsp;случае нанесения ущерба другим Пользователям, связанного с&nbsp;невыполнением требований Федерального закона &#8470;&nbsp;152-ФЗ, ответственность за&nbsp;это полностью лежит на&nbsp;Пользователе.</li>
        </ul>
        <p>4.2.1.3. Предоставить точную, актуальную, полную и&nbsp;соответствующую действительности информацию, а&nbsp;также периодически обновлять регистрационные данные и&nbsp;другую информацию, которая была предоставлена при регистрации, чтобы обеспечить ее&nbsp;точность, актуальность и&nbsp;полноту.</p>
        <p>4.2.1.4. Размещая информация о&nbsp;заявке на&nbsp;Сайте, Пользователь признает, что несет единоличную ответственность за&nbsp;ее&nbsp;содержание и&nbsp;гарантирует, что сведения, указанные в&nbsp;ней, являются соответствующими действительным данным.</p>
        <p>4.2.1.5. Заключить соглашение на&nbsp;выполнение Услуг. Конкретные условия работ, включая оплату, Заказчик и&nbsp;Исполнитель заявки определяют самостоятельно. </p>
        <p>4.2.1.6. Не&nbsp;передавать свой логин и&nbsp;пароль третьим лицам. При несоблюдении данной обязанности ответственность, а&nbsp;также все негативные последствия возлагаются исключительно на&nbsp;самого Пользователя. </p>
        <p>4.2.1.7. Не&nbsp;создавать клоны на&nbsp;Сайте.</p>
        <p>4.2.1.8. Не&nbsp;предпринимать действий, направленных на&nbsp;получение доступа к&nbsp;чужим данным вопреки воле лиц, которым они принадлежат.</p>
        <p>4.2.1.9. Возместить Обществу все убытки и&nbsp;расходы, которые оно понесло вследствие нарушения Пользователем положений настоящего Соглашения.</p>
        <p>4.2.1.10. Не&nbsp;осуществлять действия, связанные с&nbsp;распоряжением Аккаунтом: не&nbsp;продавать, не&nbsp;передавать, не&nbsp;удалять и&nbsp;проч.</p>
        <p>4.2.1.11. Если у&nbsp;Пользователя возникают претензии к&nbsp;другому Пользователю, Пользователь обязан предъявлять эти требования надлежащему лицу (Поставщику услуг) и&nbsp;разрешать претензии самостоятельно и&nbsp;без участия Сайта</p>
        <p>4.2.1.12. Пользователь понимает и&nbsp;принимает, что Сайт не&nbsp;всегда проверяет информацию, опубликованную Пользователями. Некоторые данные, содержащиеся в&nbsp;заявках/услугах могут показаться оскорбительными, опасными, неправильными или вводящими в&nbsp;заблуждение. Общество рекомендует Пользователям соблюдать осторожность и&nbsp;полагаться на&nbsp;здравый смысл при использовании информации на&nbsp;Сайте. Пользователь должен принять во&nbsp;внимание, что его контрагент может выдавать себя за&nbsp;другое лицо, быть несовершеннолетним, размещать искаженную информацию и&nbsp;т.д. Использование Сервисов подразумевает, что Пользователь осознает и&nbsp;принимает эти риски, а&nbsp;также соглашается, что Сайт не&nbsp;несет ответственности за&nbsp;действия или бездействие со&nbsp;стороны других Пользователей.</p>
        <p>4.2.2. Пользователь имеет право:</p>
        <p>4.2.2.1. Использовать для своих, в&nbsp;том числе коммерческих целей, информацию, полученную на&nbsp;Сайте, включая персональные данные, для реализации целей и&nbsp;предмета настоящего Пользовательского Соглашения;</p>
        <p>4.2.2.2. Пользоваться Сервисами на&nbsp;Сайте;</p>
        <p>4.2.2.3. Оставлять заявки на&nbsp;Сайте в&nbsp;качестве Заказчика или Исполнителя услуг. </p>
        <p>4.2.2.4. Оплачивать услуги Общества в&nbsp;порядке и&nbsp;размерах, определенных настоящим соглашением;</p>
        <p>4.2.2.5. Высказывать свое мнение на&nbsp;Сайте и&nbsp;размещать свою информацию;</p>
        <h4>5. Оплата услуг Общества</h4>
        <p>5.1. Общество взымает плату за&nbsp;использование платных Сервисов и&nbsp;с&nbsp;Пользователей сайта, которые выступают в&nbsp;качестве Исполнителей услуг. Заказчики размещают информацию о&nbsp;необходимых услугах и&nbsp;работах бесплатно. </p>
        <p>5.2. Оплата платных Сервисов Сайта и/или подтверждение готовности выполнения заявки Пользователем в&nbsp;качестве исполнителя услуги, размещенной другим Пользователем в&nbsp;качестве заказчика услуги, производится Астисами (баллами) купленными у&nbsp;Общества. </p>
        <p>5.3. Стоимость платных Сервисов и&nbsp;подтверждение готовности выполнения заявки Пользователем в&nbsp;качестве исполнителя услуги, указаны на&nbsp;Сайте. </p>
        <h4>6. Ответственность сторон</h4>
        <p>6.1. Общество не&nbsp;несет ответственности за&nbsp;убытки, причиненные Пользователю в&nbsp;результате разглашения третьим лицам его данных, произошедшего не&nbsp;по&nbsp;вине Общества. </p>
        <p>6.2. Общество прилагает все возможные усилия для обеспечения нормальной работоспособности Сайта, однако не&nbsp;несет ответственности за&nbsp;неисполнение или ненадлежащее исполнение обязательств по&nbsp;Соглашению, а&nbsp;также возможные убытки, возникшие в&nbsp;результате:</p>
        <p>6.2.1. неправомерных действий Пользователей, направленных на&nbsp;нарушение информационной безопасности или нормального функционирования Сайта.</p>
        <p>6.2.2. сбоев в&nbsp;работе Сайта, вызванных ошибками в&nbsp;коде, компьютерными вирусами и&nbsp;иными посторонними фрагментами кода в&nbsp;программном обеспечении Сайта.</p>
        <p>6.2.3. проведения государственными, муниципальными, налоговыми органами, а&nbsp;также иными организациями мероприятий в&nbsp;рамках оперативно-розыскных мероприятий.</p>
        <p>6.2.4. установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в&nbsp;сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение Соглашения.</p>
        <p>6.2.5. других случаев, связанных с&nbsp;действиями (бездействием) Пользователей и/или других субъектов, направленными на&nbsp;ухудшение общей ситуации с&nbsp;использованием сети Интернет и/или компьютерного оборудования, существовавшей на&nbsp;момент заключения Соглашения, а&nbsp;также любых других действий, направленных на&nbsp;Сайт и&nbsp;на&nbsp;третьих лиц.</p>
        <p>6.3. Общество не&nbsp;является стороной сделок, заключаемых между Заказчика и&nbsp;Исполнителями услуг, в&nbsp;качестве которых выступают Пользователи сайта и&nbsp;не&nbsp;несет ответственности за&nbsp;их&nbsp;выполнение. </p>
        <p>6.4. Пользователи Сайта, выступающие в&nbsp;качестве Заказчиков и&nbsp;Исполнителей услуг, самостоятельно заключают между собой соглашения, в&nbsp;которых определяется их&nbsp;взаимная ответственность. Она не&nbsp;регулируется нормами настоящего Соглашения. </p>
        <h4>7. Вступление Соглашения в&nbsp;силу и&nbsp;иные положения</h4>
        <p>7.1. Соглашение вступает в&nbsp;силу для Пользователей с&nbsp;момента публикации Соглашения на&nbsp;Сайте, а&nbsp;для всех иных лиц, которые на&nbsp;момент публикации настоящего Соглашения еще не&nbsp;являются Пользователями,&nbsp;&mdash; с&nbsp;момента акцепта настоящей оферты. Акцептом (безусловным принятием условий настоящего Соглашения) настоящей оферты считается осуществление лицом регистрации на&nbsp;Сайте путем заполнения регистрационной формы и&nbsp;выражения согласия с&nbsp;условиями Соглашения при нажатии кнопки &laquo;Зарегистрироваться&raquo;, размещенной на&nbsp;странице Сайта с&nbsp;регистрационной формой</p>
        <p>7.2. Соглашение заключается на&nbsp;неопределенный срок.</p>
        <p>7.3. Направление корреспонденции, связанной с&nbsp;исполнением настоящего Соглашения, может производиться средствами почтовой или электронной связи. Корреспонденция, переданная посредством электронной связи с&nbsp;электронными подписями уполномоченных лиц, имеет такую&nbsp;же юридическую силу, как и&nbsp;оригиналы. </p>
        <p>7.4. Дизайн и&nbsp;программный код Сайта, информационное, графическое, аудио-, видео-, фото- и&nbsp;иное наполнение Сайта являются интеллектуальной собственностью Общества и&nbsp;охраняются в&nbsp;соответствии с&nbsp;законодательством РФ. Никто не&nbsp;имеет права воспроизводить, копировать, модифицировать, рассылать, передавать или распространять каким-либо способом размещенные материалы.</p>
        <p>7.5. Общество вправе, а&nbsp;Пользователь настоящим дает свое согласие на&nbsp;это, передать свои права и/или обязанности по&nbsp;настоящему Пользовательскому соглашению, как в&nbsp;целом, так и&nbsp;в&nbsp;части, третьей стороне.</p>
        <p>7.6. Вопросы, не&nbsp;урегулированные Соглашением и&nbsp;документами, указанными в&nbsp;Соглашении, подлежат разрешению в&nbsp;соответствии с&nbsp;законодательством&nbsp;РФ.</p>
          <b-form-group>
            <b-form-checkbox
              :value="true"
              v-model="agree_1"
              >Я даю согласие на сбор и хранение ООО "Кибе-трейд"  моих персональных данных в соответствии с ФЗ-152  и Пользовательским соглашением.</b-form-checkbox>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              :value="true"
              v-model="agree_2"
              >Я соглашаюсь с условиями данного Пользовательского Соглашения</b-form-checkbox>
          </b-form-group>

          <b-button @click="goToStep2()" class="my-4 btn btn-lg btn-secondary my-4">Продолжить</b-button>
        </div>
        <div class="col-md-4 offset-md-4" v-if="2 === step">
          <div class="text-center">
              <div>
                <b-form>
                    <div class="text-left mt-5">
                      <h2 class="py-3">Введите номер телефона</h2>
                      <p>Чтобы зарегистрироваться<br>в&nbsp;сервисе, введите ваш телефон</p>
                    </div>
                    <b-form-group>
                        <b-form-input
                            id="phone"
                            v-model="form.phone"
                            type="text"
                            class="no-border-input"
                            :state="validateState('phone')"
                            placeholder="79991002030"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.phone"></FormInputValidate>
                    </b-form-group>
                    <b-button @click="onPhoneCheck()" class="my-4 btn btn-lg btn-secondary my-4">Продолжить</b-button>
                </b-form>    
              </div>                                                                                
          </div>
        </div>
      </div>
      <ModalAuthPhone/>
    </div>
</template>


<script>
import ModalAuthPhone from './ModalAuthPhone.vue'
import { validationMixin } from "vuelidate";
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import FormInputValidate from "./Modules/FormInputValidate.vue";

export default {
    data() {
      return {
        step: 1,
        agree_1: false,
        agree_2: false,
        form: {
          phone: '',
        }
      }
    },
    validations: {
      form: {
        phone: {
          required,
          numeric,
          minLength: minLength(11),
          maxLength: maxLength(11),
        },
      }
    },
    components: {
      ModalAuthPhone,
      FormInputValidate,
    },
    mixins: [validationMixin],
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      goToStep2(){
        if(this.agree_1 && this.agree_2) {
          this.step = 2;
        } else {
          alert('Вы должны согласиться с условиями пользовательского соглашения');
        }
      },
      onPhoneCheck() {
        var self = this;
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        self.api.get('register/phone/check', {"phone": self.form.phone})
        .then(function(response) {
          if(response.data.success){
            console.log(response);
            self.$store.state.phone = self.form.phone;
            self.$bvModal.show('modalAuthPhone');
            return;
          }
          alert(response.data.message);
        });
      },
    }   
}
</script>