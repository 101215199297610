<template>
    <div class="container">
      <div v-show="show" class="row">
        <div class="col-md-4 offset-md-4">
          <div class="text-center">                          
            <h1>{{title}}</h1>
              <div v-show="1 === step">
                  <div>Как вы хотите работать</div>
                  <button @click='form.step1.company_type = 1; changeStep(3);' class="btn btn-lg btn-secondary mt-4 mb-2">Физическое лицо</button>
                  <button @click='form.step1.company_type = 2; changeStep(2);' class="btn btn-lg btn-secondary my-2">Юридическое лицо</button>
              </div>                                           
              <div v-show="2 === step">
                  <div>Добавьте организацию</div>
                  <b-form>
                    <b-form-group>
                        <b-form-input
                            id="company_name"
                            v-model="form.step2.company_name"
                            type="text"
                            :state="validateState('step2','company_name')"
                            placeholder="Название организации"
                            class="no-border-input mt-4"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step2.company_name"></FormInputValidate>
                      </b-form-group>
                      <b-form-group>
                        <b-form-input
                            id="company_inn"
                            v-model="form.step2.company_inn"
                            type="text"
                            :state="validateState('step2','company_inn')"
                            placeholder="ИНН организации"
                            class="no-border-input"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step2.company_inn"></FormInputValidate>
                    </b-form-group>
                    <b-button @click="changeStep(3)" variant="primary" class="btn btn-secondary btn-lg my-4">Продолжить</b-button>
                </b-form>
              </div>                                           
              <div v-show="3 === step">
                  <div>Контактные данные</div>
                  <b-form>
                    <b-form-group>
                        <b-form-input
                            id="referrer_code"
                            v-model="form.step3.referrer_code"
                            type="text"
                            placeholder="Код реферала"
                            :state="validateState('step3','referrer_code')"
                            class="no-border-input mt-4"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step3.referrer_code"></FormInputValidate>
                      </b-form-group>
                      <b-form-group>
                        <b-form-input
                            id="firstname"
                            v-model="form.step3.firstname"
                            type="text"
                            :state="validateState('step3','firstname')"
                            placeholder="Имя"
                            class="no-border-input"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step3.firstname"></FormInputValidate>
                      </b-form-group>
                      <b-form-group>
                        <b-form-input
                            id="lastname"
                            v-model="form.step3.lastname"
                            type="text"
                            :state="validateState('step3','lastname')"
                            placeholder="Фамилия"
                            class="no-border-input"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step3.lastname"></FormInputValidate>
                      </b-form-group>
                      <b-form-group>
                        <b-form-input
                            id="email"
                            v-model="form.step3.email"
                            type="text"
                            :state="validateState('step3','email')"
                            placeholder="E-mail"
                            class="no-border-input"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step3.email"></FormInputValidate>
                      </b-form-group>
                      <b-form-group>
                        <b-form-input
                            id="contact_phone"
                            v-model="form.step3.contact_phone"
                            type="text"
                            :state="validateState('step3','contact_phone')"
                            placeholder="Контактный телефон"
                            class="no-border-input"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step3.contact_phone"></FormInputValidate>
                      </b-form-group>
                      <b-form-group>
                        <b-form-input
                            id="contact_phone"
                            v-model="form.step3.password"
                            type="text"
                            placeholder="Пароль"
                            :state="validateState('step3','password')"
                            class="no-border-input"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step3.password"></FormInputValidate>
                      </b-form-group>
                      <b-form-group>
                        <b-form-input
                            id="contact_phone"
                            v-model="form.step3.password2"
                            type="text"
                            placeholder="Повторите пароль"
                            :state="validateState('step3','password2')"
                            class="no-border-input"
                            >
                        </b-form-input>
                        <FormInputValidate :vdata="$v.form.step3.password2"></FormInputValidate>
                    </b-form-group>
                    <b-button @click="changeStep(4)" variant="primary" class="btn btn-secondary btn-lg my-4">Продолжить</b-button>
                </b-form>
              </div>                                           
              <div v-show="4 === step">
                  <div>В каком регионе вы хотите находить или размещать заказы</div>
                  <b-form>
                    <b-form-group>
                        <b-form-select class="no-border-input mt-4" @change="onRegionSelected($event)" v-model="form.step4.region_id" :state="validateState('step4','region_id')" :options="options.regions"></b-form-select>
                        <FormInputValidate :vdata="$v.form.step4.region_id"></FormInputValidate>
                    </b-form-group>
                    <b-form-group>
                        <b-form-select class="no-border-input" v-model="form.step4.city_id" placeholder="Выберите город" :state="validateState('step4','city_id')" :options="options.cities" v-if="options.cities.length"></b-form-select>
                        <FormInputValidate :vdata="$v.form.step4.city_id"></FormInputValidate>
                    </b-form-group>
                    <b-button @click="sendRegisterForm()" class="btn btn-secondary btn-lg my-4">Продолжить</b-button>
                </b-form>
              </div>                                           
              <div v-show="5 === step">
                  <div>Данные сохранены</div>
              </div>                                           
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import mixinAuth from './../mixins/mixinAuth';
import { validationMixin } from "vuelidate";
import { required, numeric, minLength, maxLength, email } from 'vuelidate/lib/validators'
import FormInputValidate from "./Modules/FormInputValidate.vue";

export default {
    mixins: [mixinAuth, validationMixin],
    components: {
      FormInputValidate
    },
    data() {
      return {
        show: false,
        title: 'Регистрация',
        form: {
          step1: {
            company_type: null,
          },
          step2: {
            company_name: null,
            company_inn: null,
          },
          step3: {
            referrer_code: null,
            firstname: null,
            lastname: null,
            email: null,
            contact_phone: null,
            password: null,
            password2: null,
          },
          step4: {
            region_id: null,
            city_id: null,
          },
        },
        step: 1,
        options: {
          regions: [],
          cities: []
        }
      }
    },
    validations: {
      form: {
        step2: {
          company_name: {
            required,
          },
          company_inn: {
            required,
            numeric,
            minLength: minLength(11),
            maxLength: maxLength(12),
          }
        },
        step3: {
          referrer_code: {
            numeric,
          },
          firstname: {
            required,
          },
          lastname: {
            required,
          },
          email: {
            required,
            email,
          },
          contact_phone: {
            required,
            numeric,
            minLength: minLength(11),
            maxLength: maxLength(11),
          },
          password: {
            required,
            minLength: minLength(5),
          },
          password2: {
            required,
            minLength: minLength(5),
          },
        },
        step4: {
          region_id: {
            required,
          },
          city_id: {
            required,
          },
        },
      }
    },
    created: function () {
      var self = this;
      self.api.get('reference-book/regions')
      .then(function(response) {
        self.options.regions = response.data;
      }); 

      setTimeout(function(){
        if(self.$store.state.user.company_type){
          self.title = "Профиль";
          self.changeStep(3);
        }
        
        self.form.step2.company_name = self.$store.state.user.company_name;
        self.form.step2.company_inn = self.$store.state.user.company_inn;
        self.form.step3.firstname = self.$store.state.user.firstname;
        self.form.step3.lastname = self.$store.state.user.lastname;
        self.form.step3.email = self.$store.state.user.email;
        self.form.step3.contact_phone = self.$store.state.user.contact_phone;
        self.form.step4.region_id = self.$store.state.user.region_id;
        if(self.form.step4.region_id) {
          self.onRegionSelected();
        }
        self.form.step4.city_id = self.$store.state.user.city_id;

        self.form.step3.referrer_code = self.$cookie.get("referral_code");
        self.form.step1.company_type = self.$store.state.user.company_type;
        self.show = true;
      }, 500);
      

    },
    methods: {
      validateState(step, name) {
        const { $dirty, $error } = this.$v.form[step][name];
        return $dirty ? !$error : null;
      },
      onRegionSelected() {
          var self = this;
          self.api.get('reference-book/cities', {"region_id": this.form.step4.region_id})
          .then(function(response) {
            self.options.cities = response.data;
          }); 
      },
      sendRegisterForm() {
        this.$v.form.step4.$touch();
        if (this.$v.form.step4.$anyError) {
          return;
        }
        // TODO: отправка формы на бек, если ок выводим 6 экран
        console.log(this.form);
        var self = this;
        self.api.get('profile/save', {'form': this.form})
        .then(function() {
          self.loadUserInfo();
          self.changeStep(5);
        }); 
      },
      changeStep(step) {
        if(3 === step && 2 === this.form.step1.company_type) {
          this.$v.form.step2.$touch();
          if (this.$v.form.step2.$anyError) {
            return;
          }
        }
        if(4 === step) {
          this.$v.form.step3.$touch();
          if (this.$v.form.step3.$anyError) {
            return;
          }
          if(this.form.step3.password !== this.form.step3.password2) {
            alert('Пароли не совпадают');
            return;
          }
        }
        this.step = step;
        return false;
      }
    }   
}
</script>